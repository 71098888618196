import { useHead } from '@unhead/vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { arrayify, truthy } from '@lasso/shared/utils'

export const useInitHead = () => {
  const route = useRoute()

  useHead(computed(() => {
    const routeTitle = arrayify(route.meta.head?.title ?? route.meta.title)
    const title = [...routeTitle, 'Activation', 'Media OS'].filter(truthy).join(' | ')

    return { title }
  }))
}
