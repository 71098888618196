<template>
  <Modal
    v-model="modelValue"
    title="Universal Pixel Code"
    size="xl"
  >
    <Paper
      flex
      justify="center"
      rounded="lg"
      color="base-200"
      class="code"
    >
      <Box v-if="scriptText" flex space="2">
        <Typography
          variant="body2"
          color="textPrimary"
          whiteSpace="pre-wrap"
          wordBreak="words"
        >
          {{ scriptText }}
        </Typography>
        <Box flex>
          <Tooltip content="Copy to clipboard" placement="top">
            <ButtonIcon size="xs" color="secondary" icon="content_copy" iconVariant="outlined" @click="onCopy()" />
          </Tooltip>
          <Tooltip content="Download" placement="top">
            <ButtonIcon size="xs" color="secondary" icon="file_download" iconVariant="outlined" @click="onDownload()" />
          </Tooltip>
        </Box>
      </Box>
      <EmptyState v-else message="Your pixel code is not ready yet. Please check back in a few minutes." />
    </Paper>

    <Typography variant="caption1" color="textSecondaryLight">
      To enable site-based activity tracking, paste this code in the advertiser's website header. This is required to
      create audiences for retargeting, conversion tracking, and optimization settings.
    </Typography>

    <template #actions>
      <Button size="sm" variant="outlined" @click="toggle(false)">
        Close
      </Button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Box, Button, ButtonIcon, EmptyState, Modal, Paper, Tooltip, Typography } from '@lasso/luikit'
import { useIntervalFn, useToggle } from '@vueuse/core'
import { useApiCache, useToast } from '@lasso/shared/hooks'
import { downloadData } from '@lasso/shared/utils'
import { useActivationApi } from '@lasso/api-activation/activation'
import { watch } from 'vue'

const { scriptText } = defineProps<{
  scriptText: string
}>()

const modelValue = defineModel<boolean>({ required: true })
const toggle = useToggle(modelValue)

const toast = useToast()
const { clearCacheFor } = useApiCache()
const api = useActivationApi()

const onCopy = async () => {
  try {
    await navigator.clipboard.writeText(scriptText)
    toast.success('Copied to clipboard')
  }
  catch {
    toast.error('Failed to copy pixel code')
  }
}

const onDownload = async () => {
  downloadData(scriptText, 'universal_pixel_code.txt')
}

const clearCache = () => {
  clearCacheFor([
    api.getUniversalPixel,
    api.getUniversalPixels,
  ])
}

const { pause, resume } = useIntervalFn(clearCache, 15_000, {
  immediate: false,
  immediateCallback: true,
})

watch([modelValue, () => scriptText], ([modelValue, scriptText]) => {
  if (modelValue && !scriptText) {
    resume()
  }
  else {
    pause()
  }
}, { immediate: true })
</script>

<style scoped>
.code {
  @apply px-6 py-4;
  min-height: 224px;
}
</style>
