import { RouteRecordRaw } from 'vue-router'

import { RouteName } from '../types'

import { digitalEventsRoutes } from './digitalEvents'

const routes: RouteRecordRaw[] = [
  ...digitalEventsRoutes,
]

export const rootRoute: RouteRecordRaw = {
  path: '/app/activation',
  name: RouteName.Root,
  redirect: { name: RouteName.DigitalEvents },
  children: routes,
}
