<template>
  <RouterView v-slot="slotProps">
    <component :is="slotProps.Component" v-if="isLoggedIn" />
  </RouterView>
</template>

<script setup lang="ts">
import { useInitHead } from '@activation-objects/app/useInitHead'
import { useInitApi } from '@activation-objects/app/useInitApi'
import { useInitRouter } from '@activation-objects/app/useInitRouter'
import { useInitLuikit } from '@activation-objects/app/useInitLuikit'
import { useUser } from '@lasso/shared/hooks'

useInitLuikit()
useInitApi()
useInitHead()
useInitRouter()

const { isLoggedIn } = useUser()
</script>

<style>
@tailwind utilities;
</style>
