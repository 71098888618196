import { TableColumns } from '@lasso/luikit'
import { UniversalPixelInfo } from '@lasso/api-activation/activation'
import { formatDate } from '@lasso/shared/utils'

import { UniversalPixelRow } from './types'

export const allColumns: TableColumns<UniversalPixelRow> = [
  {
    id: 'name',
    label: 'Name',
    maxWidth: '800px',
    sortable: true,
  },
  {
    id: 'marketerName',
    label: 'Marketer',
    maxWidth: '450px',
    sortable: true,
  },
  {
    id: 'createdDate',
    label: 'Created',
    sortable: true,
    modifier: val => formatDate(val, 'longDateAbbr'),
  },
]

export const sortableColumns = allColumns.filter(column => column.sortable).map(column => column.id)

export const columnSortMap: Record<
  keyof UniversalPixelRow,
  keyof UniversalPixelInfo | undefined
> = {
  id: 'id',
  name: 'name',
  marketerId: 'marketerId',
  advertiserId: 'advertiserId',
  createdDate: 'createdDate',
  uuid: undefined,
  scriptText: undefined,
  marketerName: 'marketerName',
  advertiserName: 'advertiserName',
}
