import { createInjectionState } from '@lasso/shared/utils'
import {
  UniversalPixelAudienceInfo,
  UniversalPixelConditionOperator,
} from '@lasso/api-activation/activation'
import { useBetterForm } from '@lasso/shared/hooks'
import { computed, reactive, ref } from 'vue'
import { useAccessControl } from '@activation-objects/hooks/useAccessControl'

import { AudienceConditionInput, AudienceFormInput } from './types'
import { useAudienceSchema } from './useAudienceSchema'
import { maxConditionsQty } from './consts'

export const [useProvideAudienceForm, useAudienceForm] = createInjectionState((data?: UniversalPixelAudienceInfo) => {
  const { permissions } = useAccessControl()

  const { validationSchema } = useAudienceSchema()

  const getInitialValues = (): AudienceFormInput => {
    if (!data) {
      return {
        name: '',
        conditionsOperator: UniversalPixelConditionOperator.AND,
        conditions: [{
          type: undefined,
          comparison: undefined,
          keywords: [],
        }],
      }
    }

    return {
      name: data.name,
      conditionsOperator: data.operator,
      conditions: data.conditions.map((condition): AudienceConditionInput => {
        return {
          type: condition.type,
          comparison: condition.comparison ?? undefined,
          keywords: condition.keywords ?? [],
        }
      }),
    }
  }

  const {
    useFieldModels,
    handleSubmit,
    getId,
    useFieldArray,
    useFieldArrayModels,
    submitting,
    dirty,
    formRef,
    resetForm,
  } = useBetterForm({
    validationSchema,
    initialValues: ref(getInitialValues()),
  })

  const { name, conditionsOperator } = useFieldModels(['name', 'conditionsOperator'])
  const conditions = reactive(useFieldArray('conditions'))

  const editing = Boolean(data)
  const maxConditionsReached = computed(() => conditions.fields.length >= maxConditionsQty)
  const formDisabled = computed(() => submitting.value || !permissions.digitalEvents.canManage)

  return {
    useFieldArrayModels,
    name,
    conditionsOperator,
    handleSubmit,
    getId,
    formRef,
    submitting,
    dirty,
    resetForm,
    editing,
    conditions,
    maxConditionsReached,
    formDisabled,
  }
})
