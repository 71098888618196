<template>
  <Box>
    <DropdownList width="80px" tag="div" :disabled="formDisabled">
      <Chip
        :label="operatorLabels[conditionsOperator]"
        variant="filled"
        color="primary"
        :disabled="formDisabled"
        clickable
        selectable
      />

      <template #dropdown="{ hide }">
        <ListItemButton
          v-for="{ value, label } in operators"
          :key="value"
          @click="onChangeOperator(value, hide)"
        >
          {{ label }}
        </ListItemButton>
      </template>
    </DropdownList>
  </Box>
</template>

<script setup lang="ts">
import { Box, Chip, DropdownList, ListItemButton, buildSelectOptionsFromRecord } from '@lasso/luikit'
import { UniversalPixelConditionOperator } from '@lasso/api-activation/activation'

import { computed } from 'vue'

import { useAudienceForm } from './useAudienceForm'

const { conditionsOperator, formDisabled } = useAudienceForm()!

const operatorLabels: Record<UniversalPixelConditionOperator, string> = {
  [UniversalPixelConditionOperator.AND]: 'AND',
  [UniversalPixelConditionOperator.OR]: 'OR',
}

const operators = computed(() => {
  return buildSelectOptionsFromRecord(operatorLabels)
    .filter(operator => operator.value !== conditionsOperator.value)
})

const onChangeOperator = (value: UniversalPixelConditionOperator, hide: () => void) => {
  conditionsOperator.value = value
  hide()
}
</script>
