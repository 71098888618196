import { useApiPaginated } from '@lasso/shared/hooks'
import { buildSorting, useActivationApi } from '@lasso/api-activation/activation'
import { computed, toValue } from 'vue'

import { RefOrGetter } from '@lasso/shared/types'

import { UniversalPixelAudienceRow } from './types'
import { useAudiencesQuery } from './useAudiencesQuery'
import { columnSortMap } from './consts'

export const useAudiencesApi = ({ pixelId }: {
  pixelId: RefOrGetter<number>
}) => {
  const { pageNumber, pageSize, pageSizes, sorting, defaultSorting, searchQuery } = useAudiencesQuery()

  const { data, fetching, error, retry, total, pagesTotal } = useApiPaginated(
    useActivationApi().getUniversalPixelAudiences,
    page => [{
      universalPixelId: toValue(pixelId),
      searchQuery: searchQuery.value,
      sorting: buildSorting(sorting.value, columnSortMap),
      paging: {
        pageSize: page.size,
        skip: page.skip,
      },
    }],
    {
      pageSize,
      pageNumber,
      getTotalResults: data => data.total ?? 0,
      refetch: true,
      cache: true,
      refetchOnCacheClear: true,
    },
  )

  const audiences = computed(() => {
    return (data.value?.data ?? []).map((audience): UniversalPixelAudienceRow => {
      return {
        id: audience.id,
        audienceConversionId: audience.audienceConversionId,
        name: audience.name,
        createdDate: audience.createdDate,
      }
    })
  })

  return {
    audiences,
    loading: fetching,
    error,
    retry,

    pageNumber,
    pageSize,
    pageSizes,
    total,
    pagesTotal,
    sorting,
    defaultSorting,
    searchQuery,
  }
}
