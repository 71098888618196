import { defineRefStore } from '@lasso/shared/utils'
import { UserRole, useAccessControlBase, useUser } from '@lasso/shared/hooks'
import { computed, reactive } from 'vue'

export const useAccessControl = defineRefStore('accessControl', () => {
  const { feature, permission } = useAccessControlBase()
  const { hasRole } = useUser()

  const permissions = reactive({
    digitalEvents: {
      any: permission('webevents'),
      canView: permission('webevents', 'View'),
      canManage: permission('webevents', 'Manage'),
      canViewPixelsFromMultipleMarketers: computed(() => hasRole([UserRole.Observer, UserRole.SuperAdmin])),
    },
  })

  const features = reactive({
    universalPixels: feature('UNIVERSAL_PIXELS'),
  })

  return { permissions, features }
})
