<template>
  <ModalBase
    variant="drawer"
    modelValue
    title="Edit Site Event"
    noSelfClose
    size="lg"
    @close="onClose()"
  >
    <ErrorState v-if="error" :error="error" @retry="retry()" />
    <AudienceFormSkeleton v-else-if="loading" />
    <UniversalPixelAudienceForm
      v-else-if="data"
      :key="dataTimestamp"
      :pixelId="pixelId"
      :audience="data.data"
      @close="onClose()"
    />
  </ModalBase>
</template>

<script setup lang="ts">
import { ErrorState, ModalBase } from '@lasso/luikit'
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'
import { RouteName } from '@activation-objects/router'
import { onError404 } from '@lasso/api-shared'

import { UniversalPixelAudienceForm } from '../UniversalPixelAudienceForm'

import AudienceFormSkeleton from './AudienceFormSkeleton.vue'

const { pixelId, audienceId } = defineProps<{
  pixelId: number
  audienceId: number
}>()

const router = useRouter()
const route = useRoute()

const onClose = () => {
  router.push({
    name: RouteName.UniversalPixelDetails,
    params: { pixelId },
    query: route.query,
  })
}

const { data, dataTimestamp, onData, onError, error, loading, retry } = useApi(
  useActivationApi().getUniversalPixelAudience,
  () => [audienceId],
  { refetch: true },
)

onData(({ data }) => {
  if (data.universalPixelId !== pixelId) {
    router.replace({
      params: {
        audienceId,
        pixelId: data.universalPixelId,
      },
      query: route.query,
    })
  }
})

onError(onError404)
</script>
