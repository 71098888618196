import { useAccessControl } from '@activation-objects/hooks/useAccessControl'
import { useUser } from '@lasso/shared/hooks'
import { useInitRouterRedirect } from '@lasso/shared/app'

export const useInitRouter = () => {
  const { user } = useUser()
  const accessControl = useAccessControl()

  useInitRouterRedirect((to) => {
    if (!user.value || !accessControl.features.universalPixels || !accessControl.permissions.digitalEvents.any) {
      return false
    }

    return to.meta.allowed?.(accessControl, user.value) ?? true
  })
}
