import { TableColumns } from '@lasso/luikit'
import { UniversalPixelAudienceInfo } from '@lasso/api-activation/activation'
import { formatDate } from '@lasso/shared/utils'

import { UniversalPixelAudienceRow } from './types'

export const allColumns: TableColumns<UniversalPixelAudienceRow> = [
  {
    id: 'audienceConversionId',
    label: 'Id',
    sortable: true,
    width: '100px',
  },
  {
    id: 'name',
    label: 'Name',
    maxWidth: '450px',
    sortable: true,
  },
  {
    id: 'createdDate',
    label: 'Created',
    sortable: true,
    modifier: val => formatDate(val, 'longDateAbbr'),
  },
]

export const sortableColumns = allColumns.filter(column => column.sortable).map(column => column.id)

export const columnSortMap: Record<
  keyof UniversalPixelAudienceRow,
  keyof UniversalPixelAudienceInfo | undefined
> = {
  id: 'id',
  audienceConversionId: 'audienceConversionId',
  name: 'name',
  createdDate: 'createdDate',
}
