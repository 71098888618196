<template>
  <ModalBase
    variant="drawer"
    modelValue
    title="Add Site Event"
    noSelfClose
    size="lg"
    @close="onClose()"
  >
    <UniversalPixelAudienceForm
      :pixelId="pixelId"
      :audience="audience"
      @close="onClose()"
    />
  </ModalBase>
</template>

<script setup lang="ts">
import { ModalBase } from '@lasso/luikit'
import { useRoute, useRouter } from 'vue-router'
import { UniversalPixelAudienceInfo } from '@lasso/api-activation/activation'
import { RouteName } from '@activation-objects/router'

import { UniversalPixelAudienceForm } from '../UniversalPixelAudienceForm'

const { pixelId, audience } = defineProps<{
  pixelId: number
  audience?: UniversalPixelAudienceInfo
}>()

const router = useRouter()
const route = useRoute()

const onClose = () => {
  router.push({
    name: RouteName.UniversalPixelDetails,
    params: { pixelId },
    query: route.query,
  })
}
</script>
