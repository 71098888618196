import { TableSortingOptional, tableColumnSortingOrEmpty } from '@lasso/luikit'

import { number, object, string } from 'yup'
import { oneOfEnum } from '@lasso/shared/validation'
import { useQuery, useUserLocalStorage } from '@lasso/shared/hooks'
import { toPartialRef, toSingleRef } from '@lasso/shared/utils'
import { Ref } from 'vue'

import { sortableColumns } from './consts'
import { UniversalPixelRow } from './types'

export const useUniversalPixelsQuery = () => {
  const pageSizes = [10, 25, 50]
  const defaultSorting: TableSortingOptional<keyof UniversalPixelRow> = {
    sortColumn: undefined,
    sortDirection: 'none',
  }

  const querySchema = object({
    page: number().default(1),
    pageSize: number().oneOf(pageSizes).default(10),
    query: string().default(''),
    sortColumn: oneOfEnum([...sortableColumns, undefined]).default(defaultSorting.sortColumn),
    sortDirection: oneOfEnum(tableColumnSortingOrEmpty).default(defaultSorting.sortDirection),
  }).default({})

  const { query, defaults, syncQuery } = useQuery(querySchema)

  const pageNumber = toSingleRef(query, 'page')
  const pageSize = toSingleRef(query, 'pageSize', () => ({ page: 1 }))
  const sorting: Ref<TableSortingOptional<keyof UniversalPixelRow>> = toPartialRef(
    query,
    ['sortColumn', 'sortDirection'],
  )
  const searchQuery = toSingleRef(query, 'query', () => ({ page: 1 }))

  const queryStorage = useUserLocalStorage('activationObjectsUniversalPixelsQuery', defaults)

  syncQuery({
    pageSize: toSingleRef(queryStorage, 'pageSize'),
    query: toSingleRef(queryStorage, 'query'),
    sortColumn: toSingleRef(queryStorage, 'sortColumn'),
    sortDirection: toSingleRef(queryStorage, 'sortDirection'),
  })

  return {
    pageNumber,
    pageSize,
    pageSizes,
    sorting,
    defaultSorting,
    searchQuery,
  }
}
