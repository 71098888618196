import { TableSorting, tableColumnSortingOrEmpty } from '@lasso/luikit'

import { number, object, string } from 'yup'
import { oneOfEnum } from '@lasso/shared/validation'
import { useQuery, useUserLocalStorage } from '@lasso/shared/hooks'
import { toPartialRef, toSingleRef } from '@lasso/shared/utils'
import { Ref } from 'vue'

import { sortableColumns } from './consts'
import { UniversalPixelAudienceRow } from './types'

export const useAudiencesQuery = () => {
  const pageSizes = [10, 25, 50]
  const defaultSorting: TableSorting<keyof UniversalPixelAudienceRow> = {
    sortColumn: 'audienceConversionId',
    sortDirection: 'desc',
  }

  const querySchema = object({
    page: number().default(1),
    pageSize: number().oneOf(pageSizes).default(10),
    query: string().default(''),
    sortColumn: oneOfEnum(sortableColumns).default(defaultSorting.sortColumn),
    sortDirection: oneOfEnum(tableColumnSortingOrEmpty).default(defaultSorting.sortDirection),
  }).default({})

  const { query, defaults, syncQuery } = useQuery(querySchema)

  const pageNumber = toSingleRef(query, 'page')
  const pageSize = toSingleRef(query, 'pageSize', () => ({ page: 1 }))
  const sorting: Ref<TableSorting<keyof UniversalPixelAudienceRow>> = toPartialRef(
    query,
    ['sortColumn', 'sortDirection'],
  )
  const searchQuery = toSingleRef(query, 'query', () => ({ page: 1 }))

  const queryStorage = useUserLocalStorage('activationObjectsUniversalPixelAudiencesQuery', defaults)

  syncQuery({
    pageSize: toSingleRef(queryStorage, 'pageSize'),
  })

  return {
    pageNumber,
    pageSize,
    pageSizes,
    sorting,
    defaultSorting,
    searchQuery,
  }
}
