<template>
  <LayoutLanding>
    <template #title>
      Digital Events
    </template>

    <template #actions>
      <Button v-if="permissions.digitalEvents.canManage" size="lg" color="primary" @click="onAddPixelClick()">
        Add Universal Pixel
      </Button>
    </template>

    <Tabs :active="activeTabName" size="lg" @update:active="onTabClick($event)">
      <TabsItem v-for="(tab, key) in tabs" :key="key" :value="key">
        {{ tab.title }}
      </TabsItem>
    </Tabs>

    <RouterView v-slot="subRoute">
      <Transitions mode="out-in">
        <Box>
          <component :is="subRoute.Component ?? Box" />
        </Box>
      </Transitions>
    </RouterView>
  </LayoutLanding>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Box, Button, Tabs, TabsItem, Transitions } from '@lasso/luikit'
import { useRoute, useRouter } from 'vue-router'
import { LayoutLanding } from '@activation-objects/components/layouts/LayoutLanding'
import { RouteName } from '@activation-objects/router'
import { navigateToAppUrl } from '@lasso/shared/utils'
import { useAccessControl } from '@activation-objects/hooks/useAccessControl'

const { permissions } = useAccessControl()

const router = useRouter()
const route = useRoute()

const tabs: Record<string, { title: string; url: string }> = {
  [RouteName.UniversalPixels]: { title: 'Universal Pixels', url: router.resolve({ name: RouteName.UniversalPixels }).path },
  LassoPixel: { title: 'Pixels', url: 'activation/web-events/pixel' },
  Retargeting: { title: 'Retargeting', url: 'activation/web-events/retargeting-audience' },
}

const activeTabName = ref<string>(RouteName.UniversalPixels)

const onTabClick = (value: string) => {
  const tab = tabs[value]

  if (tab) {
    navigateToAppUrl(tab.url)
  }
}

const onAddPixelClick = () => {
  router.push({ name: RouteName.UniversalPixelCreate, query: route.query })
}
</script>
