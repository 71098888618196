import { useApi } from '@lasso/shared/hooks'
import { AccountDTO, AccountType, ChannelENUM, useActivationLegacyApi } from '@lasso/api-activation/activation/legacy'
import { computed } from 'vue'
import { objValues } from '@lasso/shared/utils'

export const useAccounts = () => {
  const { data, loading, loaded, error, retry } = useApi(
    useActivationLegacyApi().account.getAccounts,
    { cache: true, refetchOnCacheClear: true, cacheLifetime: 60_000_000 },
  )

  const accounts = computed(() => {
    return (data.value?.data.accounts ?? [])
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
  })

  const getAccountsByType = (type: AccountType): AccountDTO[] => {
    return accounts.value.filter(account => account.accountType === type) ?? []
  }

  const getAccountById = (id: number, type: AccountType): AccountDTO | null => {
    return accounts.value.find(account => account.accountType === type && account.id === id) ?? null
  }

  const marketers = computed(() => getAccountsByType(AccountType.Marketer))
  const advertisers = computed(() => getAccountsByType(AccountType.Advertiser))

  const getMarketerExchangeId = (marketerId: number, channelId: ChannelENUM): string | null => {
    const exchangeSettings = marketers.value.find(marketer => marketer.id === marketerId)?.exchangeSettings

    if (!exchangeSettings) {
      return null
    }

    const exchangeSettingsArray = objValues(exchangeSettings)

    const marketerSeatId = exchangeSettingsArray
      .find(setting => setting.channelId === channelId)
      ?.externalId ?? null

    return marketerSeatId
  }

  return {
    accounts,
    marketers,
    advertisers,
    getAccountById,
    getMarketerExchangeId,

    loading,
    loaded,
    error,
    retry,
  }
}
