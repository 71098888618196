import { useApiManual, useToast } from '@lasso/shared/hooks'
import {
  UniversalPixelAudienceCondition,
  UniversalPixelAudienceInfo,
  useActivationApi,
} from '@lasso/api-activation/activation'
import { RouteName } from '@activation-objects/router'
import { useRoute, useRouter } from 'vue-router'
import { RefOrGetter } from '@lasso/shared/types'
import { toValue } from 'vue'

import { useAudienceForm } from './useAudienceForm'
import { AudienceConditionOutput } from './types'
import { isConditionWithQualifiers } from './utils'

export const useAudienceFormSubmit = ({ pixelId, audience }: {
  pixelId: RefOrGetter<number>
  audience: RefOrGetter<UniversalPixelAudienceInfo | undefined>
}) => {
  const router = useRouter()
  const route = useRoute()
  const toast = useToast()

  const {
    handleSubmit,
    resetForm,
    editing,
  } = useAudienceForm()!

  const api = useActivationApi()
  const clearCacheFor = [api.getUniversalPixelAudiences, api.getUniversalPixelAudience]

  const { requestThrows: createAudience } = useApiManual(api.createUniversalPixelAudience, { clearCacheFor })
  const { requestThrows: updateAudience } = useApiManual(api.updateUniversalPixelAudience, { clearCacheFor })

  const buildCondition = (condition: AudienceConditionOutput): UniversalPixelAudienceCondition => {
    const hasQualifiers = isConditionWithQualifiers(condition.type)

    return {
      type: condition.type,
      comparison: hasQualifiers ? condition.comparison : undefined,
      keywords: hasQualifiers ? condition.keywords : [],
    }
  }

  const onSubmit = handleSubmit(async (values) => {
    const _audience = toValue(audience)
    let audienceId = _audience?.id

    try {
      let result

      if (_audience) {
        result = await updateAudience({
          id: _audience.id,
          name: values.name,
          operator: values.conditionsOperator,
          conditions: values.conditions.map(buildCondition),
        })
      }
      else {
        result = await createAudience({
          universalPixelId: toValue(pixelId),
          name: values.name,
          operator: values.conditionsOperator,
          conditions: values.conditions.map(buildCondition),
        })

        audienceId = result?.universalPixelAudienceId
      }

      if (!result) {
        return
      }
    }
    catch (error) {
      toast.error(editing ? 'Failed to update site event' : 'Failed to create site event', error)
      return
    }

    toast.success(editing ? 'Successfully updated site event' : 'Successfully created site event', '', {
      actions: [{
        title: 'Show site event',
        onClick: () => {
          router.push({
            name: RouteName.UniversalPixelAudienceUpdate,
            params: { pixelId: toValue(pixelId), audienceId },
          })
        },
      }],
    })

    resetForm({ values })

    await router.push({
      name: RouteName.UniversalPixelDetails,
      params: { pixelId: toValue(pixelId) },
      query: route.query,
    })
  })

  return {
    onSubmit,
  }
}
