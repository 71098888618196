import { useApiPaginated } from '@lasso/shared/hooks'
import { buildSorting, useActivationApi } from '@lasso/api-activation/activation'
import { computed } from 'vue'

import { UniversalPixelRow } from './types'
import { useUniversalPixelsQuery } from './useUniversalPixelsQuery'
import { columnSortMap } from './consts'

export const useUniversalPixelsApi = () => {
  const { pageNumber, pageSize, pageSizes, sorting, defaultSorting, searchQuery } = useUniversalPixelsQuery()

  const { data, fetching, error, retry, total, pagesTotal } = useApiPaginated(
    useActivationApi().getUniversalPixels,
    page => [{
      searchQuery: searchQuery.value,
      sorting: buildSorting(sorting.value, columnSortMap),
      paging: {
        pageSize: page.size,
        skip: page.skip,
      },
    }],
    {
      pageSize,
      pageNumber,
      getTotalResults: data => data.total ?? 0,
      refetch: true,
      cache: true,
      refetchOnCacheClear: true,
    },
  )

  const universalPixels = computed(() => {
    return (data.value?.data ?? []).map((pixel): UniversalPixelRow => {
      return {
        id: pixel.id,
        name: pixel.name,
        marketerId: pixel.marketerId,
        advertiserId: pixel.advertiserId,
        createdDate: pixel.createdDate,
        uuid: pixel.uuid ?? '',
        scriptText: pixel.scriptText ?? '',
        marketerName: pixel.marketerName ?? '',
        advertiserName: pixel.advertiserName ?? '',
      }
    })
  })

  return {
    universalPixels,
    loading: fetching,
    error,
    retry,

    pageNumber,
    pageSize,
    pageSizes,
    total,
    pagesTotal,
    sorting,
    defaultSorting,
    searchQuery,
  }
}
