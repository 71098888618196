import { RouteRecordRaw } from 'vue-router'
import { ComponentProps } from '@lasso/shared/types'
import DigitalEventsLanding from '@activation-objects/views/DigitalEvents/DigitalEventsLanding.vue'
import UniversalPixels from '@activation-objects/views/DigitalEvents/UniversalPixels.vue'
import UniversalPixelCreate from '@activation-objects/views/DigitalEvents/UniversalPixelCreate.vue'
import UniversalPixelDetails from '@activation-objects/views/DigitalEvents/UniversalPixelDetails.vue'
import UniversalPixelAudienceCreate from '@activation-objects/views/DigitalEvents/UniversalPixelAudienceCreate.vue'
import UniversalPixelAudienceUpdate from '@activation-objects/views/DigitalEvents/UniversalPixelAudienceUpdate.vue'

import { RouteName } from '../types'

export const digitalEventsRoutes: RouteRecordRaw[] = [
  {
    path: 'digital-events',
    name: RouteName.DigitalEvents,
    component: DigitalEventsLanding,
    redirect: { name: RouteName.UniversalPixels },
    meta: {
      title: 'Digital Events',
      allowed: ({ features, permissions }) => features.universalPixels && permissions.digitalEvents.any,
    },
    children: [
      {
        path: 'universal-pixels',
        name: RouteName.UniversalPixels,
        component: UniversalPixels,
        meta: {
          title: 'Universal Pixels',
          head: {
            title: ['Universal Pixels', 'Digital Events'],
          },
          allowed: ({ features, permissions }) => features.universalPixels && permissions.digitalEvents.canView,
        },
        children: [
          {
            path: 'create',
            name: RouteName.UniversalPixelCreate,
            component: UniversalPixelCreate,
            meta: {
              title: 'Add Universal Pixel',
              head: {
                title: ['Add Universal Pixel', 'Digital Events'],
              },
              allowed: ({ features, permissions }) => features.universalPixels && permissions.digitalEvents.canManage,
              modal: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'digital-events/universal-pixels/:pixelId',
    name: RouteName.UniversalPixelDetails,
    component: UniversalPixelDetails,
    props: ({ params }): ComponentProps<typeof UniversalPixelDetails> => ({
      pixelId: parseInt(String(params.pixelId), 10),
    }),
    meta: {
      title: 'Universal Pixel Details',
      head: {
        title: ['Universal Pixel Details', 'Universal Pixels', 'Digital Events'],
      },
      allowed: ({ features, permissions }) => features.universalPixels && permissions.digitalEvents.canView,
    },
    children: [
      {
        path: 'audience/create',
        name: RouteName.UniversalPixelAudienceCreate,
        component: UniversalPixelAudienceCreate,
        props: ({ params }): ComponentProps<typeof UniversalPixelAudienceCreate> => ({
          pixelId: parseInt(String(params.pixelId), 10),
        }),
        meta: {
          title: 'Add Audience',
          head: {
            title: ['Add Audience', 'Universal Pixel Details', 'Universal Pixels', 'Digital Events'],
          },
          allowed: ({ features, permissions }) => features.universalPixels && permissions.digitalEvents.canManage,
          modal: true,
        },
      },
      {
        path: 'audience/:audienceId',
        name: RouteName.UniversalPixelAudienceUpdate,
        component: UniversalPixelAudienceUpdate,
        props: ({ params }): ComponentProps<typeof UniversalPixelAudienceUpdate> => ({
          pixelId: parseInt(String(params.pixelId), 10),
          audienceId: parseInt(String(params.audienceId), 10),
        }),
        meta: {
          title: 'Edit Audience',
          head: {
            title: ['Edit Audience', 'Universal Pixel Details', 'Universal Pixels', 'Digital Events'],
          },
          allowed: ({ features, permissions }) => features.universalPixels && permissions.digitalEvents.any,
          modal: true,
        },
      },
    ],
  },
]
