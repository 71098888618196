export enum RouteName {
  Root = 'root',

  DigitalEvents = 'DigitalEvents',
  UniversalPixels = 'UniversalPixels',
  UniversalPixelCreate = 'UniversalPixelCreate',
  UniversalPixelDetails = 'UniversalPixelDetails',
  UniversalPixelAudienceCreate = 'UniversalPixelAudienceCreate',
  UniversalPixelAudienceUpdate = 'UniversalPixelAudienceUpdate',
}
