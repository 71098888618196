<template>
  <Tooltip class="overflow-hidden">
    <Typography
      ref="titleRef"
      display="block"
      whiteSpace="nowrap"
      truncate
      variant="lato-h4"
      :color="$slots.title ? 'textPrimary' : 'textSecondaryLight'"
    >
      <slot v-if="$slots.title" name="title" />
      <slot v-else name="titlePlaceholder" />
    </Typography>
    <template v-if="overflown" #content>
      <slot v-if="$slots.title" name="title" />
      <slot v-else name="titlePlaceholder" />
    </template>
  </Tooltip>
</template>

<script setup lang="ts">
import { Tooltip, Typography } from '@lasso/luikit'
import { ComponentPublicInstance, ref } from 'vue'
import { useElementOverflown } from '@lasso/shared/hooks'

const titleRef = ref<ComponentPublicInstance>()
const { overflown } = useElementOverflown(titleRef)
</script>
