import { createRouter as createRouterBase, createWebHistory } from 'vue-router'

import { rootRoute } from './routes'
export * from './types'

const baseUrl = process.env.BASE_URL

export const createRouter = () => createRouterBase({
  history: createWebHistory(baseUrl),
  routes: [rootRoute],
  scrollBehavior: (to, from) => {
    if (to.path !== from.path && !to.meta.modal && !from.meta.modal) {
      return { top: 0 }
    }

    return undefined
  },
})
