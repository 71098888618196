import { array, object, string } from 'yup'
import { WhenConditionIs, oneOfEnum } from '@lasso/shared/validation'
import {
  UniversalPixelConditionComparison,
  UniversalPixelConditionOperator,
  UniversalPixelConditionType,
} from '@lasso/api-activation/activation'

import { isConditionWithQualifiers } from './utils'
import { maxConditionsQty } from './consts'

export const useAudienceSchema = () => {
  const whenHasQualifiers: WhenConditionIs = (schema, options) => {
    return schema.when('type', {
      is: (type: UniversalPixelConditionType | undefined) => isConditionWithQualifiers(type),
      ...options,
    })
  }

  const conditionValidationSchema = object({
    type: oneOfEnum(UniversalPixelConditionType).required(),
    comparison: whenHasQualifiers(
      // TODO: replace yup with a different library :|
      oneOfEnum<
        UniversalPixelConditionComparison,
        UniversalPixelConditionComparison | undefined,
        UniversalPixelConditionComparison | undefined
      >(UniversalPixelConditionComparison),
      { then: schema => schema.required() },
    ),
    keywords: whenHasQualifiers(
      array(
        string().default('').required().max(1000),
      )
        .default([])
        .max(100),
      { then: schema => schema.min(1) },
    ),
  })

  const validationSchema = object({
    name: string()
      .default('')
      .required()
      .max(250),
    conditionsOperator: oneOfEnum(UniversalPixelConditionOperator)
      .default(UniversalPixelConditionOperator.AND)
      .required(),
    conditions: array(conditionValidationSchema)
      .default([])
      .min(1)
      .max(maxConditionsQty)
      .required(),
  })

  return { validationSchema }
}
